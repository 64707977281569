import React, { Component } from 'react';

class AdminHomePage extends Component {
  state = {};

  render() {
    return <div>Admin Page</div>;
  }
}

export default AdminHomePage;
