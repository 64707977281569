export const userTypes = {
  ADD: 'user_add',
  CLEAR: 'user_clear',
  CLEAR_SEARCH: 'user_clear_search',
  CREATE: 'user_create',
  CREATE_API_KEY: 'user_create_api_key',
  DELETE_API_KEY: 'user_delete_api_key',
  FIND_ONE: 'user_find_one',
  FIND_ALL: 'user_find_all',
  LOADING: 'user_loading',
  REMOVE: 'user_remove',
  SEARCH: 'user_search',
  SEARCH_HOSTS: 'user_search_hosts',
  UPDATE: 'user_update',
};
